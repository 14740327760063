<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">
          Data Dokumen Pendukung
        </h4>
      </template>
      <b-row class="mb-2">
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Fotokopi Kartu Keluarga
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentKkDoc" />
          </div>
          <center><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-fk-kk variant="outline-primary">
              Ubah Dokumen
            </b-button>
            <!-- modal login-->
            <b-modal id="modal-fk-kk" cancel-variant="outline-secondary" ok-title="Upload" cancel-title="Close" centered
              title="Upload File Kartu Keluarga">
              <b-form>
                <b-form-group label="Fotokopi KK" label-for="fc_kk">
                  <validation-provider #default="{ errors }" name="Fotokopi KK" rules="required|size:500">
                    <b-form-file id="fc_kk" v-model="dataStudent.fc_kk" :state="errors.length > 0 ? false : null"
                      accept=".jpg, .png, .jpeg, .pdf" placeholder="File format .jpg, .png, .jpeg, .pdf" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-modal>
          </center>
        </b-col>
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Fotokopi Akta Kelahiran
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentAktaDoc" />
          </div>
          <center><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-fk-akta variant="outline-primary">
              Ubah Dokumen
            </b-button>
            <!-- modal login-->
            <b-modal id="modal-fk-akta" cancel-variant="outline-secondary" ok-title="Upload" cancel-title="Close" centered
              title="Upload File Akta Kelahiran">
              <b-form>
                <b-form-group label="Fotokopi KK" label-for="fc_kk">
                  <validation-provider #default="{ errors }" name="Fotokopi KK" rules="required|size:500">
                    <b-form-file id="fc_kk" v-model="dataStudent.fc_" :state="errors.length > 0 ? false : null"
                      accept=".jpg, .png, .jpeg, .pdf" placeholder="File format .jpg, .png, .jpeg, .pdf" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-modal>
          </center>
        </b-col>
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Fotokopi Ijazah
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentIjazahDoc" />
          </div>
          <center><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-fk-ijazah
              variant="outline-primary">
              Ubah Dokumen
            </b-button>
            <!-- modal login-->
            <b-modal id="modal-fk-ijazah" cancel-variant="outline-secondary" ok-title="Upload" cancel-title="Close"
              centered title="Upload File Ijazah">
              <b-form>
                <b-form-group label="Fotokopi Ijazah" label-for="fc_ijazah">
                  <validation-provider #default="{ errors }" name="Fotokopi Ijazah" rules="required|size:500">
                    <b-form-file id="fc_kk" v-model="dataStudent.fc_ijazah" :state="errors.length > 0 ? false : null"
                      accept=".jpg, .png, .jpeg, .pdf" placeholder="File format .jpg, .png, .jpeg, .pdf" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-modal>
          </center>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Foto Berwarna
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentColorFotoDoc" />
          </div>
        </b-col>
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Foto Hitam Putih
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentGrayFotoDoc" />
          </div>
        </b-col>
        <b-col cols="4">
          <h5 class="mb-0 text-center">
            Dokumen Lainnya
          </h5>
          <div class="d-flex justify-content-center align-items-center">
            <object class="p-2" width="230px" height="250px" :data="studentOtherDoc" />
          </div>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <!-- submit and reset -->
        <b-col>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="submit" variant="primary" class="mr-1"
            @click="toBack()">
            Back
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BCard, BRow, BCol, BForm, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    studentDatas: Object,
  },
  data: () => ({
    // studentDatas: {},
  }),
  computed: {
    studentKkDoc() {
      if (this.studentDatas?.fc_kk_url) {
        return this.studentDatas?.fc_kk_url
      }
      return import('@/assets/images/new/no-document.png')
    },
    studentAktaDoc() {
      if (this.studentDatas?.fc_akta_url) {
        return this.studentDatas?.fc_akta_url
      }
      return import('@/assets/images/new/no-document.png')
    },
    studentIjazahDoc() {
      if (this.studentDatas?.fc_ijazah_url) {
        return this.studentDatas?.fc_ijazah_url
      }
      return import('@/assets/images/new/no-document.png')
    },
    studentColorFotoDoc() {
      if (this.studentDatas?.color_photo_url) {
        return this.studentDatas?.color_photo_url
      }
      // eslint-disable-next-line import/no-unresolved
      return import('@/assets/images/new/no-document.png')
    },
    studentGrayFotoDoc() {
      if (this.studentDatas?.photo_url) {
        return this.studentDatas?.photo_url
      }
      return import('@/assets/images/new/no-document.png')
    },
    studentOtherDoc() {
      if (this.studentDatas?.other_document_url) {
        return this.studentDatas?.other_document_url
      }
      return import('@/assets/images/new/no-document.png')
    },
  },
  methods: {
    toBack() {
      this.$router.push({ name: 'data-siswa' })
    },
  },
}
</script>

<style></style>
