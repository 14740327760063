<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">
          Data Pendidikan Sebelumnya
        </h4>
      </template>
      <b-row>
        <b-col cols="4">
          <h5 class="mb-0">
            Mutasi
          </h5>
          <div class="mb-2 text-secondary">
            {{ studentMutation }}
          </div>
          <h5 class="mb-0">
            Asal Sekolah
          </h5>
          <div class="mb-2 text-secondary">
            {{ studentOriginSchool }}
          </div>
          <h5 class="mb-0">
            Alamat Sekolah
          </h5>
          <div class="mb-2 text-secondary">
            {{ addressOriginSchool }}
          </div>          
        </b-col>
        <b-col
          cols="4"
          class="border-left"
        >
          <h5 class="mb-0">
            Tahun Masuk
          </h5>
          <div class="mb-2 text-secondary">
            {{ yearEntryStudent }}
          </div>
          <h5 class="mb-0">
            Tahun Kelulusan
          </h5>
          <div class="mb-2 text-secondary">
            {{ yearGraduateStudent }}
          </div>
          <h5 class="mb-0">
            No SKHUN
          </h5>
          <div class="mb-2 text-secondary">
            {{ skhunStudent }}
          </div>
          <h5 class="mb-0">
            No Ijazah
          </h5>
          <div class="mb-2 text-secondary">
            {{ ijazahStudent }}
          </div>
        </b-col>
        <b-col
          cols="4"
          class="border-left"
        >
          <h5 class="mb-0">
            Alasan Pindah
          </h5>
          <div class="mb-2 text-secondary">
            {{ reasonMoveStudent }}
          </div>
          <h5 class="mb-0">
            Diterima Di Kelas
          </h5>
          <div class="mb-2 text-secondary">
            {{ acceptedClassStudent }}
          </div>
          <!-- <h5 class="mb-0">
            Program/Jurusan
          </h5>
          <div class="mb-2 text-secondary">
            {{ studentBlood }}
          </div> -->
          <h5 class="mb-0">
            Tanggal Efektif Diterima
          </h5>
          <div class="mb-2 text-secondary">
            {{ effectiveRecieved }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import dayjs from 'dayjs'
import 'dayjs/locale/id'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    studentDatas: Object,
  },
  data: () => ({
    // studentDatas: {},
  }),
  computed: {
    studentMutation() {
      return this.studentDatas?.mutation === 'N' ? 'Tidak' : 'Ya'
    },
    studentOriginSchool() {
      return this.studentDatas?.prev_educations?.data[0]?.school_origin
    },
    addressOriginSchool() {
      return this.studentDatas?.prev_educations?.data[0]?.school_address
    },
    yearEntryStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.year_of_entry
    },
    yearGraduateStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.graduation_year
    },
    skhunStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.skhun_no
    },
    ijazahStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.ijazah_no
    },
    reasonMoveStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.reason_to_move
    },
    acceptedClassStudent() {
      return this.studentDatas?.prev_educations?.data[0]?.kelas.class_desc
    },
    effectiveRecieved() {
      return `${dayjs(
        this.studentDatas?.prev_educations?.data[0]?.effective_date_received,
      )
        .locale('id')
        .format('D MMMM YYYY')}`
    },
  },
}
</script>

<style>
</style>
